<template>
  <h3 class="bh-text bh-text-left bh-text-bold">Accounts</h3>
  <p>Add account</p>
  <div class="row">
    <div class="col-10">
      <input type="text" placeholder="Email" v-model="email" />
    </div>
    <div class="col-2">
      <button class="bh-button bh-button-small" v-on:click.prevent="AddUser">Add user</button>
    </div>
  </div>

  <div class="row m-0 text-center mt-4">
    <div class="col-4">Email</div>
    <div class="col-1">Picture</div>
    <div class="col-3">Name</div>
    <div class="col-2">Role</div>
  </div>
  <div class="row bh-item text-center" v-for="account in accounts" :key="account._id">
    <div class="col-4">
      <p class="bh-text-background-secondary">
        <strong>{{ account.email }}</strong>
      </p>
    </div>
    <div class="col-1">
      <img :src="account.picture" style="width: 50px; border-radius: 100%" />
    </div>
    <div class="col-3">
      <p class="bh-text-background-secondary">
        <strong>{{ account.name }}</strong>
      </p>
    </div>
    <div class="col-2">
      <!--<p class="bh-text-background">
        <strong>{{ account.role }}</strong>
      </p>-->

      <select v-model="account.role" class="bh-text-background" v-on:change="UpdateUserRole(account.email, account.role)">
        <option value="Draft">Admin</option>
        <option value="Active">Guest</option>
      </select>
    </div>
  </div>
</template>
<script>
import AuthService from "@/services/AuthService.js";
import moment from "moment";

export default {
  name: "login",
  data() {
    return {
      email: "",
      accounts: [],
      error: "",
      success: false,
    };
  },
  validations: {},
  mounted() {
    this.GetUsers();
  },
  methods: {
    FilterDate(date) {
      if (date) return moment(String(date)).format("MM/DD/YYYY");
    },
    GetUsers() {
      return AuthService.GetUsers()
        .then(async ({ data }) => {
          this.accounts = data.response;
        })
        .catch((e) => {
          console.log(e.response);
          //window.location.href = "/error";
        });
    },
    async AddUser() {
      this.success = false;
      if (!this.CheckForm()) return;
      let data = {
        email: this.email,
        role: 'Admin'
      };

      return AuthService.AddUser(data)
        .then(async ({ data }) => {
          this.Clear();
          this.GetUsers();
          this.success = true;
        })
        .catch((e) => {
          console.log(e.response);
          //window.location.href = "/error";
        });
    },
    UpdateUserRole(email, role){
      return AuthService.UpdateUserRole({email , role})
        .then(async ({ data }) => {
          console.log("Updated");
        })
        .catch((e) => {
          this.error = e.response.data.response;
        });
    },
    Clear() {
      this.email = "";
    },
    CheckForm() {
      this.error = "";

      if (!this.email) this.error = "Invalid or missing params";

      if (!this.error) return true;
      else return false;
    },
  },
};
</script>